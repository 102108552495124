
.ScreenTitle {
	margin-bottom:  30px;
}

.ScreenTitle__header {
	margin-bottom:  20px;
}


@media only screen and (min-width: 750px) {

	.ScreenTitle {
		display:  flex;
		align-items: center;
		justify-content: space-between;
	}

	.ScreenTitle__header {
		margin-bottom:  0;
	}

}