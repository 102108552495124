.Avatar {
	display:  flex;
	align-items: center;
}

.Avatar__name {
	margin-right:  10px;
}

.Avatar__image {
	display:  flex;
	align-items: center;
	justify-content: center;
	width:  48px;
	height:  48px;
	border-radius: 100px;
	background-size: 100%;
	background-color: #02A4AE;
	background-position: center;
	font-size:  18px;
	font-weight:  700;
	color:  #FFF;
}

.Avatar__image--size-large {
	width:  150px;
	height:  150px;
	font-size:  50px;
}