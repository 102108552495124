
.FullScreenLoading {
	position:  fixed;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
	background-image:  url('spinner.svg');
	background-repeat: no-repeat;
	background-position: center;
}