
.InfoTable {
	width:  100%;
}

.InfoTable__Row {
	padding:  2px;
}

.InfoTable__Column {
	flex:  1;
}

.InfoTable__Column__label {
	font-weight:  700;
	flex:  0 1 200px;
	padding:  10px;
}

.InfoTable__Column__value {
	display:  flex;
	justify-content: space-between;
	align-items: center;
	flex:  1;
	background-color:  #F3F5F7;
	padding:  10px;
	border-radius: 2px;
}

.InfoTable__Column__value__empty {
	opacity:  0.4;
}

@media only screen and (min-width: 800px) {
	.InfoTable__Row {
		display:  flex;
	}
}


@media only screen and (min-width: 900px) {

	.InfoTable__Column {
		display:  flex;
	}

} 