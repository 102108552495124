
.ModalWindow {
	position:  fixed;
	right:  0;
	top:  0;
	bottom:  0;
	background-color :#FFF;
	width: 450px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0);
	z-index:  2;
}

.ModalWindow__header {
	padding: 20px;
}

.ModalWindow__close {
	background-image: url('close.svg');
	width:  20px;
	height:  20px;
	cursor: pointer;
}

.ModalWindow__body {
	padding: 20px 60px;
	text-align: center;
}