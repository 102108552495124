
.Message {
	display:  inline-block;
	margin:  20px 0;
	font-weight:  600;
	padding:  10px;
	border-radius: 2px;
}


.Message__body {
	display:  flex;
	align-items: center;
}


.Message__icon {
	width:  20px;
	height:  20px;
	margin-right:  10px;
}

.Message--type-error {
	color:  #BC2354;
	background-color:  #F8E9EE;
}

.Message--type-ok {
	color:  #009944;
	background-color:  #E4F1D4;
}


.Message--type-error .Message__icon {
	background-image:  url('error.svg');
}

.Message--type-ok .Message__icon {
	background-image:  url('ok.svg');
}