.Tooltip {
	position: relative;
}


.Tooltip__handle {
	width:  15px;
	height:  15px;
	background-image: url('help.svg');
	background-size: cover;
	cursor: pointer;
}




.Tooltip__text {
	display: none;
	position:  absolute;
	right:  30px;
	top:  -8px;
	padding:  6px;
	min-width:  200px;
	border-radius: 5px;
	background-color:  #000;
	color:  #FFF;
}

.Tooltip:hover .Tooltip__text {
	display:  block;
}

.Tooltip__text__arrow {
	position:  absolute;
	right:  -4px;
	border-radius: 2px;
	top:  10px;
	background-color:  #000;
	width:  10px;

	height:  10px;
	transform: rotate(45deg);
}