
.TileIcon {
	display: inline-block;
	text-align: center;
	width:  100px;
	height:  100px;
	color:  inherit;
	text-decoration: none;
	font-weight:  600;
}

.TileIcon:hover {
	opacity:  0.8;
}

.TileIcon__icon {
	border-radius: 4px;
	width:  75px;
	height:  75px;
	background-color: #999;
	margin: 0 auto 5px;
	background-size: 35px;
	background-position: center center;
}

.TileIcon__icon--colour-orange {
	background-color:  #EE7523;
}

.TileIcon__icon--colour-blue {
	background-color: #02A4AE;
}

.TileIcon__icon--colour-red {
	background-color: #BC2354;
}

.TileIcon__icon--colour-green {
	background-color: #76B82A;
}

.TileIcon__icon--colour-darkblue {
	background-color: #2A3042;
}