
.Accordian {
	margin-bottom:  20px;
}



/* Layout
-----------------------------------------------*/

.Accordian__header {
	display:  flex;
	align-items: center;
	background-color: #FFF;
	padding:  12px 15px;
	border-bottom:  1px solid #E0E0E0;
	cursor: pointer;
}

.Accordian__body {
	background-color: #FFF;
	padding:  20px;
}


/* Elements
-----------------------------------------------*/


.Accordian__header__icon {
	flex:  0 1 20px;
	height:  20px;
	width:  20px;
	margin-right:  15px;
	background-size: 100%;
	opacity: 0.5;
}

.Accordian__header__title {
	font-weight:  700;
	flex:  1;
}

.Accordian__header__handle {
	background-image:  url('down-arrow.svg');
	width:  11px;
	height:  6px;
}

/* Open */
.Accordian--open-true .Accordian__header__handle {
	transform: rotate(180deg);
}


.Accordian__body p:first-child {
	margin-top:  0;
}



.Accordian__tabs {
	background-color:  #FFF;
	border-bottom:  1px solid #E0E0E0;
	height:  47px;
}

.Accordian__tabs * {
	transition: none;
}

.Accordian__tabs__list {
	display: flex;

}

.Accordian__tabs__list__item {
	margin-left:  20px;
}

.Accordian__tabs__list__item__link {
	display:  block;
	color:  inherit;
	text-decoration: none;
	padding:  20px 5px 5px;
}

.Accordian__tabs__list__item__link:hover {
	border-bottom:  3px solid #999;
}

.Accordian__tabs__list__item--active .Accordian__tabs__list__item__link {
	border-bottom:  3px solid #02A4AE;
	font-weight:  700;
}