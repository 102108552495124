
.Sidebar {
	position:  fixed;
	left:  0;
	top:  0;
	bottom: 0;
	width:  80px;
	background-color:  #2A3042;
}

.Sidebar__logo {
	width:  80px;
	height:  80px;
	background-image:  url('assets/t.svg');
	background-position: center center;
	background-repeat: no-repeat;
}