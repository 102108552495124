
.form__field {
	margin-bottom:  20px;
}

.form__field__input {
	padding:  10px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  300px;
}

.form__field__input[type="text"]:disabled, .ListUsers__search:disabled {
	opacity:  0.5;
}