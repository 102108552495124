.StatBlock {
	display: inline-block;
	background-color: #02A4AE;
	border-left:  5px solid #008C94;
	text-align: center;
	color:  #FFF;
	padding:  10px;
	border-radius: 2px;
	font-weight:  600;
	min-width: 180px;
}

.StatBlock__value {
	font-size:  30px;
	font-weight:  500;
	
}