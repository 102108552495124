
.UserProfileScreen * {
	transition: none;
}

.UserProfileScreen {
	text-align: center;
}

.UserProfileScreen__avatar .Avatar {
	display:  flex;
	justify-content: center;
}



.UserProfileScreen__title {
	font-family: MyanmarMN;
	font-size:  20px;
	padding:  20px;
}

.UserProfileScreen__title--large {
	font-size:  30px;
}


.UserProfileScreen__action {
	color:  #02A4AE;
	font-weight:  bold;
	border:  0;
	font-family: inherit;
	font-size:  inherit;
	background-color:  #FFF;
	cursor: pointer;
}

