@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap);
.FullScreenError {
	display:  flex;
	align-items: center;
	justify-content: center;
	position:  absolute;
	top:  0; bottom:  0;
	left:  0; right:  0;
	text-align: center;
}

.FullScreenError__title {
	font-size:  30px;
	margin-bottom:  20px;
	font-weight:  700;
}

.FullScreenError__icon {
	width:  150px;
	height:  150px;
	background-size: cover;
	margin:  0 auto 20px;
}

.FullScreenError__icon--access-denied {
	background-image:  url(/static/media/access-denied.1adef6f3.svg);
}


.FullScreenError__message {
	font-size:  22px;
}
.Avatar {
	display:  flex;
	align-items: center;
}

.Avatar__name {
	margin-right:  10px;
}

.Avatar__image {
	display:  flex;
	align-items: center;
	justify-content: center;
	width:  48px;
	height:  48px;
	border-radius: 100px;
	background-size: 100%;
	background-color: #02A4AE;
	background-position: center;
	font-size:  18px;
	font-weight:  700;
	color:  #FFF;
}

.Avatar__image--size-large {
	width:  150px;
	height:  150px;
	font-size:  50px;
}

.overlay {
	display:  flex;
	justify-content: center;
	position: fixed;
	top: 0; 
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	padding-top:  100px;
	z-index:  1;
}


@media only screen and (min-width: 750px) {

	.overlay {
		align-items: center;
	}

}

.ModalWindow {
	position:  fixed;
	right:  0;
	top:  0;
	bottom:  0;
	background-color :#FFF;
	width: 450px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0);
	z-index:  2;
}

.ModalWindow__header {
	padding: 20px;
}

.ModalWindow__close {
	background-image: url(/static/media/close.1bc06388.svg);
	width:  20px;
	height:  20px;
	cursor: pointer;
}

.ModalWindow__body {
	padding: 20px 60px;
	text-align: center;
}

.UserProfileScreen * {
	transition: none;
}

.UserProfileScreen {
	text-align: center;
}

.UserProfileScreen__avatar .Avatar {
	display:  flex;
	justify-content: center;
}



.UserProfileScreen__title {
	font-family: MyanmarMN;
	font-size:  20px;
	padding:  20px;
}

.UserProfileScreen__title--large {
	font-size:  30px;
}


.UserProfileScreen__action {
	color:  #02A4AE;
	font-weight:  bold;
	border:  0;
	font-family: inherit;
	font-size:  inherit;
	background-color:  #FFF;
	cursor: pointer;
}


.Header {
	display: flex;
	align-items: center;
	padding: 10px 20px !important;
	background-color:  #FFF;
}

.Header__logo {
	flex:  1 1;
	line-height: 0;
}

.Header__logo__img {
	width:  110px;
}

.Header__user {
	cursor:  pointer;
}




@media only screen and (min-width: 600px) {

	


}

.Navigation {
	margin-top:  50px;
}

.Navigation__list__item {
	margin-bottom:  10px;
}

.Navigation__list__item__link {
	display:  block;
	height:  50px;
	width:  50px;
	margin:  0 auto;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 5px;
}

.Navigation__list__item__link:hover {
	background-color: #181C26;
}
/* Fonts
-----------------------------------------------*/


/* Resetter
-----------------------------------------------*/

* {
	margin:  0;
	padding:  0;
	box-sizing: border-box;
	list-style-type: none;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	transition: all 0.5s ease;
  	background-repeat: no-repeat;
  	background-position: center center;

}


/* Body
-----------------------------------------------*/

body {
	font-size:  15px;
	font-family: 'Nunito', sans-serif;
	font-weight:  600;
	color: #374442;
	background-color: #F3F5F7;
}


/* Headings, Paragraphs and Anchors
-----------------------------------------------*/

h1, h2 {
	font-size:  18px;
}

h2 {
	margin-bottom: 20px;
}

p {
	font-size: 16px;
	color: #374442;
	margin:  25px 0;
}

a {
	color:  #02A4AE;
}



.table {
	width:  100%;
	border-collapse: collapse;
	background-color:  #FFF;
	margin:  20px 0;
}

.table th {
	padding: 10px;
	border-bottom:  1px solid #EBF0F5;
	border-top:  1px solid #EBF0F5;
	text-align: left;
	background-color:  #F7F9FC;
}

.table td {
	padding:  10px;
	border-bottom:  1px solid #EBF0F5;
}

.table tbody tr:hover td {
	background-color:  #F6F8FB;
}

.pagination {
	display:  flex;
	justify-content: space-between;
}

.pagination > div {
	cursor: pointer;
	color:  #02A4AE;
	font-weight: 600;
}

.table-caption {
	margin-top:  20px;
	opacity: 0.75;
	font-weight:  600;
}

.input {
	padding:  10px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  300px;
}


.colour--red {
	color:  #BC2354;
}


.ghost {
	opacity:  0.6;
	font-weight:  600;
}



.form__field {
	margin-bottom:  20px;
}

.form__field__input {
	padding:  10px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  300px;
}

.form__field__input[type="text"]:disabled, .ListUsers__search:disabled {
	opacity:  0.5;
}

.Sidebar {
	position:  fixed;
	left:  0;
	top:  0;
	bottom: 0;
	width:  80px;
	background-color:  #2A3042;
}

.Sidebar__logo {
	width:  80px;
	height:  80px;
	background-image:  url(/static/media/t.b6a9c884.svg);
	background-position: center center;
	background-repeat: no-repeat;
}

.EnvironmentSelector {
	position:  fixed;
	bottom:  20px;
	left:  20px;
	background-color: #FFF;
	color:  #374442;
	width:  290px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
}

.EnvironmentSelector a {
	text-decoration: none;
}

.EnvironmentSelector__items {
	border-bottom:  3px solid #DDD;
}

.EnvironmentSelector__items__item {
	display:  flex;
	align-items: center;
	border-bottom:  1px solid #DDD;
	padding:  10px 15px;
}

.EnvironmentSelector__items__item:hover {
	background-color:  #F3F5F7;
}



.EnvironmentSelector__items__item__header {
	margin-right:  15px;
}

.EnvironmentSelector__items__item__body {
	flex:  1 1;
}

.EnvironmentSelector__items__item__light {
	background-color:  #CCC;
	width:  15px;
	height:  15px;
	border-radius: 50px;
}

.EnvironmentSelector__items__item__light--red {
	background-color:  #BC2354;
}

.EnvironmentSelector__items__item__light--orange {
	background-color:  #EE7523;
}

.EnvironmentSelector__items__item__light--green {
	background-color:  #76B82A;
}

.EnvironmentSelector .EnvironmentSelector__items__item:last-child {
	border-bottom:  0;
}

.EnvironmentSelector__items__item__title {
	font-weight:  800;
}

.EnvironmentSelector__items__item__description {
	font-size:  13px;
	color:  #666;
}

.EnvironmentSelector__current {
	cursor: pointer;
}



.DefaultLayout__body {
	
}

.DefaultLayout__body {
	margin-left:  80px;
	padding:  30px;
}


.Breadcrumb {
	font-size:  14px;
	margin-bottom:  5px;
}

.Breadcrumb__list {
	display:  flex;
}

.Breadcrumb__list__item {
	display:  flex;
	align-items: center;
	margin-right:  10px;
	color:  #999;
}

.Breadcrumb__list__item__link {
	display:  block;
	margin-right:  5px;
}

.Breadcrumb__list__item:after {
	content:  ' > ';
}

.Breadcrumb__list__item:last-child:after {
	content:  '';
}

.ScreenTitle {
	margin-bottom:  30px;
}

.ScreenTitle__header {
	margin-bottom:  20px;
}


@media only screen and (min-width: 750px) {

	.ScreenTitle {
		display:  flex;
		align-items: center;
		justify-content: space-between;
	}

	.ScreenTitle__header {
		margin-bottom:  0;
	}

}

.Loading {
	display: flex;
	align-items: center;
	color: #777;
	font-weight: 600;
}

.Loading__spinner {
	background-image:  url(/static/media/spinner.26077d4d.svg);
	width: 50px;
	height: 50px;
	background-size: cover;
	margin-right: 10px;
}

.Message {
	display:  inline-block;
	margin:  20px 0;
	font-weight:  600;
	padding:  10px;
	border-radius: 2px;
}


.Message__body {
	display:  flex;
	align-items: center;
}


.Message__icon {
	width:  20px;
	height:  20px;
	margin-right:  10px;
}

.Message--type-error {
	color:  #BC2354;
	background-color:  #F8E9EE;
}

.Message--type-ok {
	color:  #009944;
	background-color:  #E4F1D4;
}


.Message--type-error .Message__icon {
	background-image:  url(/static/media/error.4835f70a.svg);
}

.Message--type-ok .Message__icon {
	background-image:  url(/static/media/ok.6b54dcda.svg);
}


.ListUsers__search {
	padding:  10px 10px 10px 40px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  400px;
	background-image:  url(/static/media/search.114ac6cd.svg);
	background-position:  left 15px center;
	font-weight:  600;
}




::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #8B8B8B;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #8B8B8B;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #8B8B8B;
}
:-moz-placeholder { /* Firefox 18- */
  color: #8B8B8B;
}

.Accordian {
	margin-bottom:  20px;
}



/* Layout
-----------------------------------------------*/

.Accordian__header {
	display:  flex;
	align-items: center;
	background-color: #FFF;
	padding:  12px 15px;
	border-bottom:  1px solid #E0E0E0;
	cursor: pointer;
}

.Accordian__body {
	background-color: #FFF;
	padding:  20px;
}


/* Elements
-----------------------------------------------*/


.Accordian__header__icon {
	flex:  0 1 20px;
	height:  20px;
	width:  20px;
	margin-right:  15px;
	background-size: 100%;
	opacity: 0.5;
}

.Accordian__header__title {
	font-weight:  700;
	flex:  1 1;
}

.Accordian__header__handle {
	background-image:  url(/static/media/down-arrow.64d5e624.svg);
	width:  11px;
	height:  6px;
}

/* Open */
.Accordian--open-true .Accordian__header__handle {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}


.Accordian__body p:first-child {
	margin-top:  0;
}



.Accordian__tabs {
	background-color:  #FFF;
	border-bottom:  1px solid #E0E0E0;
	height:  47px;
}

.Accordian__tabs * {
	transition: none;
}

.Accordian__tabs__list {
	display: flex;

}

.Accordian__tabs__list__item {
	margin-left:  20px;
}

.Accordian__tabs__list__item__link {
	display:  block;
	color:  inherit;
	text-decoration: none;
	padding:  20px 5px 5px;
}

.Accordian__tabs__list__item__link:hover {
	border-bottom:  3px solid #999;
}

.Accordian__tabs__list__item--active .Accordian__tabs__list__item__link {
	border-bottom:  3px solid #02A4AE;
	font-weight:  700;
}

.Button {
	display:  inline-block;
	color:  #FFF;
	padding:  10px 15px;
	text-align: center;
	border:  0;
	outline:  0;
	font-weight:  600;
	margin-right:  10px;
	cursor: pointer;
	border-radius: 2px;
	min-width:  100px;
	font-family: inherit;
	font-size:  inherit;
}

.Button:hover {
	opacity:  0.8;
}



/* Colour Variants
-----------------------------------------------*/

.Button--colour-blue, .Button--colour-default {
	background-color:  #1D71B8;
}

.Button--colour-orange {
	background-color:  #EE7523;
}

.Button--colour-red {
	background-color:  #BC2354;
}

.ChangePassword__suggest {
	margin-top:  5px;
	font-weight: 700;
	color:  #02A4AE;
	cursor: pointer;
	font-size:  12px;
}

.InfoTable {
	width:  100%;
}

.InfoTable__Row {
	padding:  2px;
}

.InfoTable__Column {
	flex:  1 1;
}

.InfoTable__Column__label {
	font-weight:  700;
	flex:  0 1 200px;
	padding:  10px;
}

.InfoTable__Column__value {
	display:  flex;
	justify-content: space-between;
	align-items: center;
	flex:  1 1;
	background-color:  #F3F5F7;
	padding:  10px;
	border-radius: 2px;
}

.InfoTable__Column__value__empty {
	opacity:  0.4;
}

@media only screen and (min-width: 800px) {
	.InfoTable__Row {
		display:  flex;
	}
}


@media only screen and (min-width: 900px) {

	.InfoTable__Column {
		display:  flex;
	}

} 
.Tooltip {
	position: relative;
}


.Tooltip__handle {
	width:  15px;
	height:  15px;
	background-image: url(/static/media/help.2816b027.svg);
	background-size: cover;
	cursor: pointer;
}




.Tooltip__text {
	display: none;
	position:  absolute;
	right:  30px;
	top:  -8px;
	padding:  6px;
	min-width:  200px;
	border-radius: 5px;
	background-color:  #000;
	color:  #FFF;
}

.Tooltip:hover .Tooltip__text {
	display:  block;
}

.Tooltip__text__arrow {
	position:  absolute;
	right:  -4px;
	border-radius: 2px;
	top:  10px;
	background-color:  #000;
	width:  10px;

	height:  10px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}


.TileIcon {
	display: inline-block;
	text-align: center;
	width:  100px;
	height:  100px;
	color:  inherit;
	text-decoration: none;
	font-weight:  600;
}

.TileIcon:hover {
	opacity:  0.8;
}

.TileIcon__icon {
	border-radius: 4px;
	width:  75px;
	height:  75px;
	background-color: #999;
	margin: 0 auto 5px;
	background-size: 35px;
	background-position: center center;
}

.TileIcon__icon--colour-orange {
	background-color:  #EE7523;
}

.TileIcon__icon--colour-blue {
	background-color: #02A4AE;
}

.TileIcon__icon--colour-red {
	background-color: #BC2354;
}

.TileIcon__icon--colour-green {
	background-color: #76B82A;
}

.TileIcon__icon--colour-darkblue {
	background-color: #2A3042;
}
.StatBlock {
	display: inline-block;
	background-color: #02A4AE;
	border-left:  5px solid #008C94;
	text-align: center;
	color:  #FFF;
	padding:  10px;
	border-radius: 2px;
	font-weight:  600;
	min-width: 180px;
}

.StatBlock__value {
	font-size:  30px;
	font-weight:  500;
	
}

.AppStatus__textarea {
	width:  500px !important;
	height:  100px;
	padding:  10px;
	font-size:  16px;
	font-weight:  600;
	line-height:  140%;
}

.FullScreenLoading {
	position:  fixed;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
	background-image:  url(/static/media/spinner.8d51aff2.svg);
	background-repeat: no-repeat;
	background-position: center;
}
