
.Navigation {
	margin-top:  50px;
}

.Navigation__list__item {
	margin-bottom:  10px;
}

.Navigation__list__item__link {
	display:  block;
	height:  50px;
	width:  50px;
	margin:  0 auto;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 5px;
}

.Navigation__list__item__link:hover {
	background-color: #181C26;
}