
.EnvironmentSelector {
	position:  fixed;
	bottom:  20px;
	left:  20px;
	background-color: #FFF;
	color:  #374442;
	width:  290px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
}

.EnvironmentSelector a {
	text-decoration: none;
}

.EnvironmentSelector__items {
	border-bottom:  3px solid #DDD;
}

.EnvironmentSelector__items__item {
	display:  flex;
	align-items: center;
	border-bottom:  1px solid #DDD;
	padding:  10px 15px;
}

.EnvironmentSelector__items__item:hover {
	background-color:  #F3F5F7;
}



.EnvironmentSelector__items__item__header {
	margin-right:  15px;
}

.EnvironmentSelector__items__item__body {
	flex:  1;
}

.EnvironmentSelector__items__item__light {
	background-color:  #CCC;
	width:  15px;
	height:  15px;
	border-radius: 50px;
}

.EnvironmentSelector__items__item__light--red {
	background-color:  #BC2354;
}

.EnvironmentSelector__items__item__light--orange {
	background-color:  #EE7523;
}

.EnvironmentSelector__items__item__light--green {
	background-color:  #76B82A;
}

.EnvironmentSelector .EnvironmentSelector__items__item:last-child {
	border-bottom:  0;
}

.EnvironmentSelector__items__item__title {
	font-weight:  800;
}

.EnvironmentSelector__items__item__description {
	font-size:  13px;
	color:  #666;
}

.EnvironmentSelector__current {
	cursor: pointer;
}
