

.ListUsers__search {
	padding:  10px 10px 10px 40px;
	font-family: inherit;
	font-size:  inherit;
	border:  1px solid #C9C9C9;
	border-radius: 4px;
	width:  400px;
	background-image:  url('assets/search.svg');
	background-position:  left 15px center;
	font-weight:  600;
}




::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #8B8B8B;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #8B8B8B;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #8B8B8B;
}
:-moz-placeholder { /* Firefox 18- */
  color: #8B8B8B;
}